import styled from 'styled-components';

import {
  IconLowerCosts,
  IconFasterService,
  IconImproveEfficiency,
} from '@loadsmart/icons';

//helpers
import { screen } from '@/helpers/screen';

import { Title, Text } from '@/components/Grid/Content';
import { Container } from '@/components/Grid/Container';
import { Section } from '@/components/Grid/Section';

import {
  Feature,
  FeatureGroup,
  FeatureTitle,
  FeatureDescription,
  FeatureIcon,
  responsiveIconStyle,
} from '@/components/Feature';

export const StyledFeatureGroup = styled(FeatureGroup)`
  ${screen.md} {
    margin: 0 -22px;
  }

  ${screen.lg} {
    margin: 0 -35px;
  }

  ${screen.xxlg} {
    margin: 0 -39px;
  }
`;

export const StyledFeature = styled(Feature)`
  width: 255px;
  margin: 13px 0;

  ${screen.md} {
    width: 196px;
    margin: 23px 21px;
  }

  ${screen.lg} {
    width: 258px;
    margin: 16px 35px;
  }

  ${screen.xxlg} {
    width: 325px;
    margin: 55px 39px;
  }
`;

export const IntroductionSection = () => (
  <Section>
    <Container>
      <Title>
        Enterprise shipping is a unique, complex and many-headed beast
      </Title>
      <Text>
        Enterprise shipping is sophisticated, high pressure and brings unique
        challenges for each business. Emerging technology, data insights, mode
        optimization, automation and instant execution bring massive
        opportunities to reinvent logistics and supply chain inefficiencies.
        Unfortunately, these advancements can bring their own headaches,
        complexity, risk and costs.
      </Text>
      <Text>
        Loadsmart pairs advanced technologies with deep-seated industry
        expertise to fuel growth, simplify operational complexity and bolster
        efficiency for carriers and shippers alike. As your logistics experts,
        we leverage our technology to absorb your unique objectives and provide
        you the flexible capacity you need to meet your service requirements and
        efficiency goals.
      </Text>
      <StyledFeatureGroup>
        <StyledFeature>
          <FeatureIcon>
            <IconImproveEfficiency role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Improve Service</FeatureTitle>
          <FeatureDescription>
            Meet or exceed on-time performance benchmarks with unparalleled
            precision
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconFasterService role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Save Time</FeatureTitle>
          <FeatureDescription>
            Quote and book thousands of shipments in seconds within the tools
            you use everyday
          </FeatureDescription>
        </StyledFeature>
        <StyledFeature>
          <FeatureIcon>
            <IconLowerCosts role="img" css={responsiveIconStyle} />
          </FeatureIcon>
          <FeatureTitle>Lower Costs</FeatureTitle>
          <FeatureDescription>
            Save when the market rate is below your static contracted options
          </FeatureDescription>
        </StyledFeature>
      </StyledFeatureGroup>
    </Container>
  </Section>
);
