// components
import { Container } from 'components/Grid';
import { MirandaGradientButton } from '@/components/Button';

// styles
import {
  ServicesWrapper,
  Title,
  Content,
  ButtonWrapper,
  Legend,
  LegendItem,
  ServicesList,
  ServicesContainer,
  ServicesTitle,
  ServiceItemsWrap,
  ServiceItem,
} from './Services.styles';

//assets

import IconService from '@/public/images/icons/loadsmart-services/icon-Services.svg';
import IconTech from '@/public/images/icons/loadsmart-services/icon-Tech.svg';
import IconServiceTech from '@/public/images/icons/loadsmart-services/icon-ServTech.svg';
import IconAI from '@/public/images/icons/loadsmart-services/icon-AI.svg';

const services_list = [
  {
    title: 'Assessment and Analysis',
    order: 1,
    items: [
      {
        type: 'service',
        name: 'Freight Planning and  Forecasting',
      },
      {
        type: 'service-tech',
        name: 'Network Assessment and Optimization',
      },
      {
        type: 'ai',
        name: 'FreightIntel AI',
      },
      {
        type: 'service-tech',
        name: 'Rate Analysis and Benchmarking',
      },
    ],
  },
  {
    title: 'Planning and Execution',
    order: 4,
    items: [
      {
        type: 'tech',
        name: 'Vendor Portal - Order Initiation',
      },
      {
        type: 'tech',
        name: 'Mode/Route Optimization',
      },
      {
        type: 'service-tech',
        name: 'Tracking & Visibility',
      },
      {
        type: 'tech',
        name: 'RFP and Mini-bids',
      },

      {
        type: 'tech',
        name: 'Integration hub',
      },
      {
        type: 'tech',
        name: 'Order Consolidation',
      },
      {
        type: 'tech',
        name: 'Real-time Carrier & Brokers Spot Bidding',
      },
      {
        type: 'service',
        name: 'Brokerage & Managed Transportation',
      },
      {
        type: 'service-tech',
        name: 'Carrier Sourcing, Contracting and Management',
      },
    ],
  },
  {
    title: 'Gate, Yard and Dock',
    order: 2,
    items: [
      {
        type: 'ai',
        name: 'Computer Vision Asset Tracking',
      },
      {
        type: 'tech',
        name: 'Driver Check-in/Out',
      },
      {
        type: 'tech',
        name: 'Dock Scheduling',
      },
      {
        type: 'tech',
        name: 'Yard Management',
      },
    ],
  },
  {
    title: 'Audit and Analytics',
    order: 3,
    items: [
      {
        type: 'tech',
        name: 'Freight Audit',
      },
      {
        type: 'service-tech',
        name: 'Analytics and Data as a Service',
      },
      {
        type: 'service',
        name: 'Market Intelligence and Econometrics',
      },
    ],
  },
];

export function Services() {
  function renderIcon(type) {
    switch (type) {
      case 'service-tech':
        return <IconServiceTech width="32" height="32" />;
      case 'tech':
        return <IconTech width="32" height="32" />;
      case 'ai':
        return <IconAI width="32" height="32" />;
      default:
        return <IconService width="32" height="32" />;
    }
  }
  return (
    <ServicesWrapper>
      <Container>
        <Title>Next-Gen Logistics Solutions </Title>
        <Content>
          Everything your logistics org needs from the time an order is placed
          to final invoice of the shipment.
        </Content>
        <ServicesList>
          {services_list.map((branch, key) => {
            return (
              <ServicesContainer
                className={`block-` + branch.order}
                key={key + '-' + branch.title.trim()}
              >
                <ServicesTitle>{branch.title}</ServicesTitle>
                <ServiceItemsWrap>
                  {branch.items.map((service, key) => {
                    return (
                      <ServiceItem
                        key={
                          key +
                          '-' +
                          branch.title.trim() +
                          '-' +
                          service.name.trim()
                        }
                      >
                        {renderIcon(service.type)}
                        <span> {service.name}</span>
                      </ServiceItem>
                    );
                  })}
                </ServiceItemsWrap>
              </ServicesContainer>
            );
          })}
        </ServicesList>
        <Legend>
          <LegendItem>
            <IconService width="32" height="32" />
            <span>Services</span>
          </LegendItem>
          <LegendItem>
            <IconTech width="32" height="32" />
            <span>Technology</span>
          </LegendItem>
          <LegendItem>
            <IconServiceTech width="32" height="32" />
            <span>Service + Tech</span>
          </LegendItem>
          <LegendItem>
            <IconAI width="32" height="32" />
            <span>Artificial Intelligence</span>
          </LegendItem>
        </Legend>
        <ButtonWrapper>
          <MirandaGradientButton as="a" href="/#contact-form">
            <span>Learn More</span>
          </MirandaGradientButton>
        </ButtonWrapper>
      </Container>
    </ServicesWrapper>
  );
}
