import React from 'react';

//components
import { Section, Container } from '@/components/Grid';
import { Title, Text } from '@/components/Grid/Content';

export const IntroductionSection = () => (
  <Section>
    <Container>
      <Title>Give Your Freight Context</Title>
      <Text>
        There are gaps between what public databases portray and what real-time
        markets provide. Shippers leveraging the available data will have a leg
        up on the competition for capacity, but it is not enough to reflect the
        past and lack context. Bridging the gap between publicly available data
        and real-time information is critical.
      </Text>
      <Text>
        Transportation and technology partners provide context at the micro and
        macro level. Whether for quick, actionable insights based on lead time,
        detention, and mode or for sophisticated logistics solutions to
        transform freight planning, operations, and optimization.
      </Text>
      <Text>
        The programmatic pipelines built into the supply and demand in the
        freight market give you a unique perspective to see actionable,
        real-time, aggregated freight insights as well as receive custom data
        insights from our team of engineers, data scientists, and industry
        experts.
      </Text>
    </Container>
  </Section>
);
