/* eslint-disable @next/next/no-img-element */
import React from 'react';

//components
import {
  ReversedCenteredWrapper,
  Title,
  Text,
} from '@/components/Grid/Content';

//context components
import {
  SectionStyled,
  ResponsiveImage,
  Column,
  Link,
} from './ModeOptSection.styles';

export const ModeOptSection = () => (
  <SectionStyled reverse>
    <ResponsiveImage>
      <source
        srcSet={`/images/data-insights/mode-opt/mode-optimization.png`}
        media="(min-width: 768px)"
      />
      <img
        src={`/images/data-insights/mode-opt/mode-optimization-mobile@2x.png`}
        alt="Truck Load"
        width="670"
        height="670"
      />
    </ResponsiveImage>
    <ReversedCenteredWrapper>
      <Column>
        <Title>Mode Optimization</Title>
        <Text>
          Loadmart’s algorithms recognized 30% of all FTL shipments qualified as
          suitable for rail. Get the data and intelligence necessary to
          instantly compare options and make the best decision with no
          additional rate discovery.
        </Text>
        <Text>
          Your account manager will run on-going historical and predictive
          analysis to share insights that will enable well-informed shipping
          decisions.
        </Text>
        <Link href={`/mode-optimization`}>Learn more</Link>
      </Column>
    </ReversedCenteredWrapper>
  </SectionStyled>
);
