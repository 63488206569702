//icons
import {
  IconInstantPricing,
  IconTenderAcceptance,
  IconArtificialIntelligence,
  IconSmartTracking,
  IconInvoice,
} from '@loadsmart/icons';
import { IconPackageTracking, IconVisibility } from '@loadsmart/icons';
import {
  IconFastPayment,
  IconImproveEfficiency,
  IconFasterService,
} from '@loadsmart/icons';

//componets
import { WideImageOnMobile } from '@/components/Grid/Content';

//containers
import { Footer } from '@/containers-m/Footer';
import { Navigation } from '@/containers-m/Navigation';
import {
  HubspotForm,
  HubspotFormTitle,
  HubspotFormDescription,
} from '@/containers/HubspotForm';
import { tmsIntegrationOptions } from '@/vendor/HubspotFormIntegration';

// context containers
import {
  HeroSection,
  FeatureSection,
  BackgroundImageSection,
  IntegrationAPISection,
  ContentSection,
} from './containers/index';

//styles
import { PageWrapper } from './Integrations.styles';

//content
const heroContent = {
  page: 'Blue Yonder',
  logo: {
    src: `/images/logos/logo-blue_yonder.svg`,
    alt: 'Blue Yonder System',
  },
  title: `Unlock Dynamic Price Discovery In Your Blue Yonder TMS`,
  description: `Loadsmart’s integration with Blue Yonder makes it easy to add instant
        market rates, guaranteed tender acceptance, and real-time tracking
        directly to your Blue Yonder Transportation Management System (TMS).`,
  images: {
    sm: `/images/integrations/blue-yonder/small_hero.png`,
    md: `/images/integrations/blue-yonder/medium_hero.png`,
    lg: `/images/integrations/blue-yonder/large_hero.png`,
    xxlg: `/images/integrations/blue-yonder/xxlarge_hero.png`,
  },
};
const featuresContent = {
  title: 'Capabilities',
  features: [
    {
      title: 'INSTANT RATES',
      icon: <IconInstantPricing />,
    },
    {
      title: '100% TENDER ACCEPTANCE',
      icon: <IconTenderAcceptance />,
    },
    {
      title: 'AI-POWERED SOURCING',
      icon: <IconArtificialIntelligence />,
    },
    {
      title: 'NEAR REAL-TIME TRACKING',
      icon: <IconSmartTracking />,
    },
    {
      title: 'EDI INVOICING',
      icon: <IconInvoice />,
    },
  ],
};
const benefitsContent = {
  text: `Loadsmart’s partnerships with leading ELD providers such as KeepTruckin, combined with smart apps and other technologies like geofencing, means we always know the real-time location of your shipments and can proactivelymitigate service issues before they occur.`,
  benefits: [
    {
      icon: <IconPackageTracking width={66} height={66} />,
      copy: 'View real-time tracking updates directly from within your Blue Yonder TMS',
    },
    {
      icon: <IconVisibility width={66} height={66} />,
      copy: 'Proactively identify and mitigate service issues before they occur',
    },
  ],
};

const copyContent = {
  title: 'Discover dynamic rates and instant capacity',
  introduction: ` Loadsmart’s integration with Blue Yonder fully automates the quoting and booking of truckload
                        shipments. We do this by inserting real-time market rates directly into your Blue Yonder
                        Luminate Platform, enabling you to take advantage of market conditions when current prices are
                        below the static options provided by other carriers. Loadsmart accepts 100% of tenders
                        instantly — so whenever our rate is best, the load can be immediately covered.
                    `,
  media: (
    <WideImageOnMobile
      loading="lazy"
      src={`/images/integrations/blue-yonder/half_section-smarter_blue-yonder.png`}
      alt="Blue Yonder"
    />
  ),
  benefits: [
    {
      icon: <IconFastPayment width={66} height={66} />,
      text: 'Save when the market rate is below your static contracted options',
    },
    {
      icon: <IconImproveEfficiency width={66} height={66} />,
      text: 'Improve service by lowering the number of shipments sent to the spot market',
    },
    {
      icon: <IconFasterService width={66} height={66} />,
      text: 'Reduce time spent securing coverage for your freight',
    },
  ],
};

function BlueYonder() {
  return (
    <PageWrapper>
      <Navigation />
      <>
        <HeroSection content={heroContent} />
        <FeatureSection content={featuresContent} showHeader={false} />
        <ContentSection
          content={copyContent}
          cta={{
            title: 'Watch the video',
            url: 'https://www.youtube.com/watch?v=20RWNpTtuog',
            external: true,
          }}
        />
        <BackgroundImageSection content={benefitsContent} />
        <IntegrationAPISection />
        <HubspotForm {...tmsIntegrationOptions}>
          <HubspotFormTitle>Ready to learn more?</HubspotFormTitle>
          <HubspotFormDescription>
            Fill out the form below to and a member of the Loadsmart team will
            be in touch.
          </HubspotFormDescription>
        </HubspotForm>
      </>
      <Footer />
    </PageWrapper>
  );
}

export default BlueYonder;
export { BlueYonder };
